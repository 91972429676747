import { useNavigate } from "@remix-run/react"

type Props = {
  buttonContainerProps: string;
  buttonProps: string;
  buttonName: string;
  routeName:string;
}

const MyButton = (props: Props) => {
	const navigate = useNavigate()

  const navigateToRoute = (): void => {
    if (props.routeName === '-1') navigate(-1)
    navigate(`/${props.routeName}`)
  }

  return (
    <div className={`${props.buttonContainerProps}`}>
      <button
        className={`${props.buttonProps} button-colors`}
        onClick={navigateToRoute}
      >
        {props.buttonName}
      </button>
    </div>
  )
}

export default MyButton